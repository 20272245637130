import { render, staticRenderFns } from "./executives.vue?vue&type=template&id=67c3d81b&scoped=true"
import script from "./executives.vue?vue&type=script&lang=js"
export * from "./executives.vue?vue&type=script&lang=js"
import style0 from "./executives.vue?vue&type=style&index=0&id=67c3d81b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c3d81b",
  null
  
)

export default component.exports