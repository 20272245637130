<template>
  <!-- 高管信息 -->
  <div>
    <div class="banner" id="aboutus_banner1"  :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img src="../../assets/images/aboutus/aboutus_banner.jpg" /> -->
      <span
        >{{ $t("indexnav.executives")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Executive Information</i
        ></span
      >
    </div>
    <section class="ab-item1">
      <!-- 跳转 -->
      <!-- <a href="/">{{ $t("responsibility.Home") }}</a> >
      <a href="/aboutus/synopsis">{{
        $t("responsibility.About JingXin")
      }}</a>
      >
      {{ $t("indexnav.executives") }} -->
      <bread-crumb />
    </section>
    <div id="iframeContainer">
      <div class="container ir-info">
        <ul>
          <li>
            <h2>{{ $t("aboutus.公司管理团队") }}</h2>
            <p></p>
            <div class="info-row" v-for="i in gaoguanList" :key="i.id">
              <!-- {{i}} -->
              <div class="key">
                <a class="senior-link" @click="jump(i)">{{ i.title }}</a>
              </div>
              <div>
                {{ i.subTitle }}
              </div>
            </div>
            <p></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from "@/api/manage";
import { getArticle, getAlltype } from "@/api/news";
import { PageType } from '@/utils/constant';
export default {
  data() {
    return {
      nowLanguageData: "",
      gaoguanList: [],
      banner:''
    };
  },
  created() {
      // 初始化banner
    let that = this
    getAction('/jeecg-system/common/frontend/advertise/active_list', { typeCode: PageType.GAOGUANXINGXI }).then(res => {
      if (res.result.records instanceof Array && res.result.records.length > 0) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg; 
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });
    if (this.$store.state.nowlanguage == "zh") {
      this.nowLanguageData = "sample";
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.nowLanguageData = "tranditional";
    }
    if (this.$store.state.nowlanguage == "en") {
      this.nowLanguageData = "EN";
    }
    // getAction("/jeecg-system/common/frontend/article/active_list", {
    //   typeId: "1564456183996092418",
    //   languageType: "sample",
    // }).then((res) => {
    //   // console.log(res);
    // });
    // getAlltype().then((res) => {
    //   // console.log(res);
    //   // for (let i of res.result) {
    //   //   if (i.id == this.$route.query.type) {
    //   //     console.log(i);
    //   //     this.nowType = i.name;
    //   //   }
    //   // }
    // });
    getAction("/jeecg-system/common/frontend/article/active_list", {
      typeId: "1559892075984457730",
      languageType: this.nowLanguageData,
      pageSize: 100,
    }).then((res) => {
      console.log(res);
      this.gaoguanList = res.result.records;
    });
  },
  methods: {
    jump(i) {
      console.log(i);
      if (i.typeDictId == "sub_text") {
        this.$router.push("/newsinfo?id=" + i.id);
      }
      if (i.typeDictId == "url") {
        window.open(i.outUrl);
      }
    },
  },
  computed: {
    nowlanguage() {
      return this.$store.state.nowlanguage;
    },
    // getlang() {
    //   if (this.$store.state.nowlanguage == "zh") {
    //     this.nowLanguageData = "sample";
    //     return "sample";
    //   }
    //   if (this.$store.state.nowlanguage == "tr") {
    //     this.nowLanguageData = "tranditional";
    //     return "tranditional";
    //   }
    //   if (this.$store.state.nowlanguage == "en") {
    //     this.nowLanguageData = "EN";
    //     return "EN";
    //   }
    // },
  },
  watch: {
    // nowlanguage(newvalue, oldvalue) {
    //   if (newvalue == "zh") {
    //     this.nowLanguageData = "sample";
    //   }
    //   if (newvalue == "tr") {
    //     this.nowLanguageData = "tranditional";
    //   }
    //   if (newvalue == "en") {
    //     this.nowLanguageData = "EN";
    //   }
    // },
  },
};
</script>
<style lang='less' scoped>
.banner {
  max-width: 100vw;
  position: relative;
  /* min-height: 72vh; */
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
  /* height: 72vh; */
}
.banner > span {
  width: 100%;
  height: 100%;
  font-size: 3rem;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  > i {
    font-size: 2rem;
    font-style: normal;
    opacity: 0.5;
  }
}
#iframeContainer {
    margin: 3em auto 0 auto;
    padding: 0 0.5em;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .info-row {
  font-size: 16px!important;
  margin-bottom: 12px;
  display: flex;
  font: 16px/2 "宋体", Arial;
  color: #666666;
  div:last-child{
    flex: 1;
  }
}
.key {
  width: 180px!important;
}
}
.info-row {
  font-size: 16px!important;
  margin-bottom: 12px;
  display: flex;
  font: 16px/2 "宋体", Arial;
  color: #666666;
  div:last-child{
    flex: 1;
  }
.key {
  width: 380px;
}
}

.ir-info ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1024px;
    margin: auto;
}

.key a:hover {
  color: #e60044;
}
.key a {
  color: #666666;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
      display: flex;
    // width: 100px;
    word-break: break-all;
}
.container {
    margin: auto;
    overflow: hidden;
    padding: 0 20px;
}

.senior-link {
  cursor: pointer;
  color: #666666;
}
.container h2 {
  font-size: 1rem;
  color: #f00;
  padding-bottom: 10px;
  font-weight: normal;
  font: 16px/ 2 "宋体", Arial;
}
</style>
